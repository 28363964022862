import eventsHandler from '@/services/common/eventsHandler.js'

class EnablingTypeCategoriesForm extends eventsHandler {
  constructor() {
    super()
    this._enablingTypeCategory = {}
    this._valid = false
  }

  get enablingTypeCategory() {
    const enablingTypeCategory = {
      ...this._enablingTypeCategory
    }
    return enablingTypeCategory
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._enablingTypeCategory.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetEnablingTypeCategory() {
    this._enablingTypeCategory = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {enablingTypeCategory: {}})
  }

  updateField(key, value) {
    this._enablingTypeCategory[key] = value
    this._executeCallbacksOf('update', {enablingTypeCategory: this._enablingTypeCategory})
    this._executeCallbacksOf('update-' + key, {key: this._enablingTypeCategory[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(enablingTypeCategory) {
    this._enablingTypeCategory = enablingTypeCategory
    this._executeCallbacksOf('update', {enablingTypeCategory: this._enablingTypeCategory})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new EnablingTypeCategoriesForm()